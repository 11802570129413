<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit;"
      class="d-flex flex-column"
    >
      <div
        style="height: 100%; padding:0px; min-height: inherit;"
        class="d-flex flex-row"
      >
        <side-menu class="hidden-sm-and-down" />
        <content-section />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import ContentSection from "./AdminReportedContent.vue";
import SideMenu from "../home/AdminHomeSideMenu";
export default {
  components: { ContentSection, SideMenu },
  data() {
    return {
      snackbar: false,
      errorMessage: null
    };
  },
  methods: {
    onSelectEvent(event) {
      console.log(event);
    }
  }
};
</script>
<style scoped>
::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}
</style>
